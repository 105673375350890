import { useState } from '../../../library/base/baseContainerImports'

const CouponForm = ({addCoupon, loading, coupon}) => {
    const [couponCode, setCouponCode] = useState('');

    const handleChange = (event) => {
        setCouponCode(event.target.value);
    };

    return (
        <>
            <div>
                <h6>If you have a coupon code, please enter here and click Apply button.</h6>
            </div>
            <div className="row mt-10">
                <div className="form-coupon col-md-12"/>
                <div className="col-md-4">
                    <div className="form-group">
                        <input
                            type="text"
                            className="form-control"
                            onChange={handleChange}
                            id="inputCoupon"
                            placeholder="Enter Coupon"
                            disabled={loading}
                            defaultValue={coupon}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <button
                        type="button"
                        className="btn btn-primary mb-2"
                        onClick={() => {addCoupon(couponCode)}}
                        disabled={loading}
                    >
                        Apply {coupon && (<i className="icon-check"/>)}
                    </button>
                </div>
            </div>
        </>
    );
}

export default CouponForm;