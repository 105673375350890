import React from 'react'
import {
    $, CompanyRoutes,
    queryString,
    urlConfigs,
    useActionUrl,
    useEffect,
    useLocation,
    useState
} from '../../library/base/baseContainerImports'
import EventInfo from './Components/EventInfo'
import BookingForm from './Components/BookingForm'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Loading from './Components/Loading'

function decodeEncodedString(encodedString) {
    const decodedString = Buffer.from(encodedString, 'hex').toString('utf-8');
    const a = decodedString.split('|')
    return a[1];
}

const EventBooking = () => {
    const history = useHistory();
    const userToken = useSelector(state => state.auth.token);

    const [event, setEvent] = useState(null);
    const [availability, setAvailability] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingPricing, setLoadingPricing] = useState(false);
    const [step, setStep] = useState(1);
    const [bookingFee, setBookingFee] = useState(0);
    const [quantity, setQuantity] = useState(1);
    const [selectedBooked, setSelectedBooked] = useState([]);
    const [selectedWaiting, setSelectedWaiting] = useState([]);
    const [companyLogo, setCompanyLogo] = useState('');
    const [pricing, setPricing] = useState({});

    const {getFullUrl} = useActionUrl();
    const location = useLocation();
    const {e: e_encode, b, authhash, status} = queryString.parse(location.search);
    const e = e_encode && decodeEncodedString(e_encode);
    const eventUrl = getFullUrl('/api/v1/booking', {e, authhash});
    const sessionUrl = getFullUrl('/api/v1/booking/sessions', {e, authhash});

    if (status === 'true' && step !== 3) setStep(3);

    const fetchEvent = () => {
        setLoading(true);
        $.get(eventUrl, function (data) {
            setEvent(data);
            setLoading(false);
        }).fail(() => {
            setLoading(false);
        });
    }

    const fetchSession = () => {
        setLoading(true);
        $.get(sessionUrl, function (data) {
            setAvailability(data);
            setLoading(false);
        }).fail(() => {
            setLoading(false);
        });
    }

    const calculatePrice = () => {
        setLoadingPricing(true);
        const data = {
            event_id: e,
            quantity,
            booked: selectedBooked,
        }
        const formAction = getFullUrl(
            "/api/v1/calculate-charges-summary",
            {
                authhash,
                event_id: e,
                quantity,
                booked: selectedBooked,
                type_of_registration: 'event'
            }
        )
        $.get(formAction, function (data) {
            setPricing(data);
            setBookingFee(data.pricing_details.booking_fee.value || 0);
            setLoadingPricing(false);
        });
    }

    const companyBranding = () => {
        const formAction    = urlConfigs.BASE_URL + '/rest/acl?authhash=' + authhash;
        $.get(formAction, function (data){
            if (data?.meta?.logo) {
                setCompanyLogo(data.meta.logo);
            }
        });
    };

    const updateItems = (items, setItems, value) => {
        if (items.includes(value)) {
            setItems(items.filter(item => item !== value)); // Remove item if already selected
        } else {
            setItems([...items, value]); // Add item if not selected
        }
    };

    const handleCheckboxChange = (value, type) => {
        if (type === 'booked') {
            updateItems(selectedBooked, setSelectedBooked, value);
        } else if (type === 'waiting') {
            updateItems(selectedWaiting, setSelectedWaiting, value);
        }
    };

    const handleCheckout = () => {
        const formData = {
            event_name: event?.name,
            event_id: e,
            quantity,
            booked: selectedBooked,
            waiting: selectedWaiting,
            bookingFee,
            pricing
        }
        localStorage.setItem('checkoutData', JSON.stringify(formData));
        const checkoutPath = `/booking/checkout?authhash=${authhash}&b=${b}`;

        if (userToken) {
            history.push(checkoutPath);
        } else {
            const checkoutUrl = urlConfigs.LOCAL_URL.replace('/admin/', '') + checkoutPath
            history.push(`admin/students/login?authhash=${authhash}&redirect=${checkoutUrl}`);
        }
    };

    useEffect(() => {
        calculatePrice();
    }, [selectedBooked]);

    useEffect(() => {
        setSelectedBooked([])
        setSelectedWaiting([])
    }, [quantity]);

    useEffect(() => {
        fetchEvent();
        fetchSession();
        companyBranding();
    }, []);

    const isAvailable = (Array.isArray(availability) && availability.length > 0) ||
        (typeof availability === 'object' && availability !== null && Object.keys(availability).length > 0);

    const renderStep = () => {
        if (loading) return <Loading />;

        switch (step) {
            case 1:
                return <EventInfo event={event} setStep={setStep} isAvailable={isAvailable} />;
            case 2:
                return <BookingForm
                    event={event}
                    availability={availability}
                    setStep={setStep}
                    bookingFee={bookingFee}
                    quantity={quantity}
                    setQuantity={setQuantity}
                    handleCheckboxChange={handleCheckboxChange}
                    showCheckoutButton={Boolean(selectedBooked.length > 0 || selectedWaiting.length > 0) &&  !loading && !loadingPricing}
                    selectedBooked={selectedBooked}
                    selectedWaiting={selectedWaiting}
                    handleCheckout={handleCheckout}
                />;
            case 3:
                return (
                    <div class="alert alert-success" role="alert">
                        <span>Booking Successful!</span>&nbsp;
                        <a href={CompanyRoutes.ADMIN.STUDENTS.PROFILE.path}>Go to my account</a>
                    </div>
                );
        }
    }


    return (
        <div className="booking-event">
            <div className="container">
                <div className=" border-bottom border-color-light-grey pb-10 mb-10">
                    <div className="display-block text-slate-300 text-center">
                        {companyLogo && <img alt="Logo" src={companyLogo}/>}
                    </div>
                    {userToken && <a href={CompanyRoutes.ADMIN.STUDENTS.PROFILE.path}><i
                        className="icon-arrow-left7"></i> Go
                        to my account</a>}
                </div>
                {renderStep()}
            </div>
        </div>
    );
}

export default EventBooking;
