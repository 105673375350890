import Loading from './Components/Loading'
import React from 'react'
import {
    $,
    queryString,
    urlConfigs,
    useActionUrl,
    useEffect, useFormInit,
    useLocation,
    useState,
    showMessage, showUserMessage,
} from '../../library/base/baseContainerImports'
import Form from '../../library/basic/forms/Form'

const PartyWaiver = () => {
    const [loading, setLoading] = useState(true);
    const [companyLogo, setCompanyLogo] = useState('');

    const location = useLocation();
    const {id, authhash} = queryString.parse(location.search);

    const {getFormActions} = useActionUrl();

    const formAfterPopulate = () => {
        $("#check_waiver").click(function(){
            const formData = $(".form_waiver").serialize();
            const urlCheck = urlConfigs.BASE_URL + '/api/v1/birthday-party/waiver/check?authhash=' + authhash
            $.post(urlCheck, formData, function (result){
                if (!result.valid){
                    showMessage( $(".activate_message") , result.message );
                    return false;
                }else{
                    if ($(".nNote").length){
                        $(".nNote").remove();
                    }
                    if (result.found){
                        const guest_id = $("#bday_guest_id").val();
                        const urlMark = `${urlConfigs.BASE_URL}/api/v1/birthday-party/waiver/mark?authhash=${authhash}&guest_id=${guest_id}&hash=${id}&waiver_id=${result.id}`;
                        $.get(urlMark, function (result){
                            if (result.valid){
                                showUserMessage (
                                    "Waiver Form Successfully Submitted!",
                                    "We located your previously signed Waiver and therefore, you do not have to sign it again.",
                                    1) ;
                                $(".signup-area").hide();
                            }else{
                                showUserMessage (
                                    "Waiver Form Submission Failed!",
                                    "We encountered a technical issue and please try again.",
                                    2) ;
                            }
                        });
                    }else{
                        $(".waiver_validator").remove();
                        $(".waiver_info").slideDown();
                    }
                }
            });

            return false;
        });

        $(".submit_waiver").click(function(){
            $("form.form_waiver").processForm({
                mode: "callback", callback: function (valid, response) {
                    if (valid) {
                        showUserMessage (
                            "Waiver Form Successfully Submitted!",
                            "We located your previously signed Waiver and therefore, you do not have to sign it again.",
                            1) ;
                        setTimeout(function() {
                            window.location.reload();
                        }, 1000);
                        return false;
                    } else {
                        showUserMessage (
                            "Waiver Form Submission Failed!",
                            "We encountered a technical issue and please try again.",
                            2) ;
                        return false;
                    }
                }
            });
        });
    }

    const formId = "form-party-waiver-data";
    const {formAction, formDefinitionAction} = getFormActions('/api/v1/birthday-party/waiver', {authhash, id});
    const {fetchFormData} = useFormInit({formId, formAction, formAfterPopulate});

    const companyBranding = () => {
        setLoading(true)
        const formAction    = urlConfigs.BASE_URL + '/rest/acl?authhash=' + authhash;
        $.get(formAction, function (data){
            if (data?.meta?.logo) {
                setCompanyLogo(data.meta.logo);
            }
            setLoading(false)
        });
    };

    useEffect(() => {
        companyBranding();
    }, []);

    return (
        <div className="waiver" style={{width: '100vw'}}>
            <div className="container">
                {loading ? (<Loading/>) : (
                    <>
                        <div
                            className="display-block text-slate-300 border-bottom border-color-light-grey pb-10 mb-10 text-center">
                            {companyLogo && <img alt="Logo" src={companyLogo}/>}
                        </div>
                        <div>
                            <div className="activate_message"></div>
                            <Form
                                id={formId}
                                action={formAction}
                                formClass="form_data generic_form form_waiver"
                                encType="application/x-www-form-urlencoded"
                                jsonLoadAction={formDefinitionAction}
                                jsonLoadActionHandler={fetchFormData}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default PartyWaiver