import React from 'react'
import {
    $, CompanyRoutes,
    queryString,
    urlConfigs,
    useActionUrl,
    useEffect,
    useLocation,
    useState
} from '../../library/base/baseContainerImports'
import Loading from './Components/Loading'
import { Calendar } from '@demark-pro/react-booking-calendar'
import "@demark-pro/react-booking-calendar/dist/react-booking-calendar.css";
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import './Css/BirthdayPartyBooking.css'

export function formatDate(date, formatType = 'short') {
    const d = new Date(date);
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const monthIndex = d.getMonth();
    const day = d.getDate();
    const year = d.getFullYear();

    if (formatType === 'long') {
        return `${months[monthIndex]} ${day}, ${year}`;
    } else{
        const formattedMonth = (monthIndex + 1 < 10) ? `0${monthIndex + 1}` : `${monthIndex + 1}`;
        const formattedDay = (day < 10) ? `0${day}` : `${day}`;
        return `${year}-${formattedMonth}-${formattedDay}`;
    }
}

const BirthdayPartyBooking = () => {
    const history = useHistory();
    const userToken = useSelector(state => state.auth.token);
    const location = useLocation();
    const {getFullUrl} = useActionUrl();
    const {status, authhash = localStorage.getItem('authHash') || '' } = queryString.parse(location.search);

    const [loading, setLoading] = useState(false);
    const [loadingCalendar, setLoadingCalendar] = useState(false);
    const [loadingSlotCapacity, setLoadingSlotCapacity] = useState(true);
    const [loadingPricing, setLoadingPricing] = useState(false);
    const [companyLogo, setCompanyLogo] = useState('');
    const [calendar, setCalendar] = useState({});
    const [reserved, setReserved] = useState([]);
    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState(new Date().getMonth());
    const [selectedDate, setSelectedDate] = useState(null);
    const [slotCapacity, setSlotCapacity] = useState(null);
    const [slotSelected, setSlotSelected] = useState(null);
    const [capacitySelected, setCapacitySelected] = useState(null);
    const [pricing, setPricing] = useState(null);

    const handleChange = (e) => {
        setSelectedDate(e[0]);
    }

    const companyBranding = () => {
        setLoading(true)
        const formAction    = urlConfigs.BASE_URL + '/rest/acl?authhash=' + authhash;
        $.get(formAction, function (data){
            if (data?.meta?.logo) {
                setCompanyLogo(data.meta.logo);
            }
            setLoading(false)
        });
    };

    const fetchCalendar = (year) => {
        setLoadingCalendar(true)
        const formAction    = `${urlConfigs.BASE_URL}/api/v1/birthday-party/booking/load-calendar?authhash=${authhash}&year=${year}`;
        $.get(formAction, function (data){
            setCalendar(prevCalendar => {
                return { ...prevCalendar, ...JSON.parse(data.calendar) };
            });
            setLoadingCalendar(false)
        }).fail(() => {
            setLoadingCalendar(false)
        });
    };

    const calculateReserved = () => {
        if (!!calendar) {
            const oneDay = 86400000;
            const reservedData = [];
            for (var key in calendar) {
                if (calendar.hasOwnProperty(key)) {
                    const parsedData = JSON.parse(calendar[key]);
                    if (parsedData.available === 0) {
                        const [year, month, day] = key.split('-').map(Number);
                        const startDate = new Date(year, month - 1, day);
                        reservedData.push({
                            startDate,
                            endDate: new Date(startDate.getTime() + oneDay - 1),
                        });
                    }
                }
            }
            setReserved(reservedData);
        }
    }

    const fetchSlot = () => {
        setLoadingSlotCapacity(true);
        const formattedDate = formatDate(selectedDate);
        const formAction = getFullUrl("/api/v1/birthday-party/booking/slot-capacity", {authhash, date: formattedDate})
        $.get(formAction, function (data){
            setSlotCapacity(data);
            setSlotSelected(data.slots[0].id);
            setCapacitySelected(1);
            setLoadingSlotCapacity(false)
        })
    }

    const fetchPricing = () => {
        setLoadingPricing(true)
        const formattedDate = formatDate(selectedDate);
        const formAction = getFullUrl(
            "/api/v1/calculate-charges-summary",
            {
                authhash,
                date: formattedDate,
                quantity: capacitySelected,
                type_of_registration: 'party'
            }
        )
        $.get(formAction, function (data){
            setPricing(data);
            setLoadingPricing(false)
        })
    }

    useEffect(() => {
        if (selectedDate || capacitySelected) fetchPricing();
    }, [selectedDate, capacitySelected]);

    useEffect(() => {
        companyBranding();
    }, []);

    useEffect(() => {
        calculateReserved();
    }, [calendar]);

    useEffect(() => {
        if (!!selectedDate){
            fetchSlot();
        }
    }, [selectedDate]);

    useEffect(() => {
        const keys = Object.keys(calendar);
        const yearFetched = keys.some(date => date.startsWith(year.toString()));
        if (!yearFetched) fetchCalendar(year);
    }, [year])

    const DayContent = ({date, state, children, innerProps}) => {
        const formattedDate = formatDate(date);

        if (state.isReserved) return null;

        if (calendar[formattedDate]) {
            const parsedData = JSON.parse(calendar[formattedDate]);

            return (
                <div {...innerProps} >
                    <div className="calendar__day-content">
                        {children}
                        <span>{parsedData.available} slots</span>
                    </div>
                </div>
            );
        }

        return (
            <div className="disabled-text" {...innerProps} >
                <div className="calendar__day-content">
                    <p>{children}</p>
                    <i className="icon-lock2"></i>
                </div>
            </div>
        );
    }

    const DayReservation = ({date, state, innerProps}) => {
        if (state.isReserved) {
            const day = date.getDate();
            return (
                <div className="disabled-text" {...innerProps} >
                    <div className="calendar__day-content">
                        <p>{day}</p>
                        <i className="icon-lock2"></i>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }

    const onMonthChange = (month, year) => {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();

        if (year < currentYear || (year === currentYear && month < currentMonth)) {
            return; // Do nothing if the given month and year are less than the current month and year
        }

        setMonth(month);
        setYear(year);
    }

    const renderCalendar = () => {
        if (loadingCalendar) return <Loading />;

        const isEmpty = Object.keys(calendar).length === 0;

        if (isEmpty) return <div className="alert alert-danger" role="alert">Something wrong!</div>

        return <Calendar
            selected={[selectedDate]}
            onChange={handleChange}
            // onOverbook={(e, err) => alert(err)}
            month={month}
            year={year}
            components={{
                DayContent,
                DayReservation,
            }}
            disabled={(date, state) => !state.isSameMonth}
            reserved={reserved}
            dateFnsOptions={{ weekStartsOn: 1 }}
            range={false}
            onMonthChange={onMonthChange}
        />
    }

    const renderSlotOption = () => {
        if (!slotCapacity) return <></>;
        return slotCapacity.slots.map(value => {
            return <option key={value.id} selected={value.id === slotSelected} value={value.id}>{value.name}</option>
        });
    }

    const renderCapacityOption = () => {
        if (!slotCapacity) return <></>;
        let options = [];
        for(let i = 1; i <= parseInt(slotCapacity.capacity[slotSelected]); i++) {
            options.push(<option key={i} selected={i === capacitySelected} value={i}>{i}</option>);
        }

        return options;
    }

    const renderPricing = () => {
        if (loadingPricing) return <Loading />;

        if (!pricing) return <div class="alert alert-danger" role="alert">Something wrong!</div>

        const slot = slotCapacity.slots.find(item => item.id == slotSelected);

        return (
            <div className="mb-10">
                <table className="table">
                    <tr>
                        <td className="table-row"><b>Party Date</b></td>
                        <td className="table-row">{formatDate(selectedDate, 'long')}</td>
                    </tr>
                    <tr>
                        <td className="table-row"><b>Time</b></td>
                        <td className="table-row">{slot?.name}</td>
                    </tr>
                    <tr>
                        <td className="table-row"><b>Quantity</b></td>
                        <td className="table-row">{capacitySelected}</td>
                    </tr>
                    <tr>
                        <td className="table-row"><b>Price</b></td>
                        <td className="table-row">{pricing?.pricing_details?.booking_fee?.formatted}</td>
                    </tr>
                    <tr>
                        <td className="dark-row">Total</td>
                        <td className="dark-row">{pricing?.pricing_details?.booking_fee?.formatted}</td>
                    </tr>
                </table>
            </div>
        )
    }

    const handleCheckout = () => {
        const slot = slotCapacity.slots.find(item => item.id == slotSelected);

        const formData = {
            slot_id: slotSelected,
            time: slot.name,
            date: formatDate(selectedDate),
            quantity: capacitySelected,
            pricing,
        }
        localStorage.setItem('checkoutBirthdayData', JSON.stringify(formData));
        const checkoutPath = `/birthday-party/booking/checkout?authhash=${authhash}`;

        if (userToken) {
            history.push(checkoutPath);
        } else {
            const checkoutUrl = urlConfigs.LOCAL_URL.replace('/admin/', '') + checkoutPath
            history.push(`/admin/students/login?authhash=${authhash}&redirect=${checkoutUrl}`);
        }
    };


    return (
        <div className="booking-event">
            <div className="container">
                {loading ? (<Loading />) :  (
                    <>
                        <div className="border-bottom border-color-light-grey pb-10 mb-10">
                            <div className="display-block text-slate-300 text-center">
                                {companyLogo && <img alt="Logo" src={companyLogo}/>}
                            </div>
                            {userToken && <a href={CompanyRoutes.ADMIN.STUDENTS.PROFILE.path}><i
                                className="icon-arrow-left7"></i> Go
                                to my account</a>}
                        </div>
                        {status ? (
                            <div className="alert alert-success" role="alert">
                                <span>Booking Successful!</span>&nbsp;
                                <a href={CompanyRoutes.ADMIN.STUDENTS.PROFILE.path}>Go to my account</a>
                            </div>
                        ) : (
                            <div className="custom-row">
                                <div className="custom-column box">
                                    {renderCalendar()}
                                </div>
                                <div className="custom-column">
                                    {selectedDate ? (
                                        <div className="row">
                                            {loadingSlotCapacity ? (<Loading />) : (
                                                <>
                                                    <div className="col-md-12 box">
                                                        <div className="form-group">
                                                            <label htmlFor="slot">{formatDate(selectedDate, 'long')}</label>
                                                            <select
                                                                id="slot"
                                                                defaultValue={slotSelected}
                                                                className="form-control"
                                                                onChange={(e) => {
                                                                    setSlotSelected(e.target.value)
                                                                    setCapacitySelected(1)
                                                                }}>
                                                                {renderSlotOption()}
                                                            </select>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="capacity">Quantity</label>
                                                            <select id="capacity" defaultValue={capacitySelected} className="form-control" onChange={(e) => setCapacitySelected(e.target.value)}>
                                                                {renderCapacityOption()}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 mt-10 box">
                                                        <h3 class="table-row">Reservation</h3>
                                                        {renderPricing()}
                                                        <button className="btn btn-primary" disabled={loadingPricing && loadingCalendar && loading && loadingSlotCapacity} onClick={handleCheckout}>Book Now</button>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    ) : (
                                        <div className="box">
                                            Please select the days from calendar.
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

export default BirthdayPartyBooking;
