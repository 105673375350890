import React from "react";
import {getRouteForModule, getRouteObject} from './companyRouteUtils';

/**
 * Company route configuration
 *
 * Use getRouteForModule to create routes for all operations including main container
 * Use getRouteObject to get a single route object
 *
 * Check above method signatures in `adminRouteUtils` for more information
 */
export const CompanyRoutes = {
    "ADMIN": {
        ...getRouteObject('', '', '', 'Dashboard'),
        "GOOGLE_OAUTH": {
            ...getRouteForModule('google-oauth', 'GoogleOauth', 'Connect your Google Account'),
            "GOOGLE_OAUTH_LINK": {
                ...getRouteForModule('google-oauth/link', 'GoogleOauth/Link', 'Connect your Google Account')
            }
        },
        "DASHBOARD_SESSIONS": {
            ...getRouteObject('sessions', '', '', 'Dashboard Calendar'),
        },
        "MY_ACCOUNT": getRouteForModule('my-account', 'User/MyAccount', 'My Account'),
        "MY_COMPANY": {
            ...getRouteForModule('my-company', 'User/MyCompany', 'My Company', '','icon-office'),
            "MY_LOGO": {
                ...getRouteObject('my-company/my-logo', null, 'User/MyCompany', 'My Logo'),
                "UPLOAD": getRouteObject('my-company/my-logo/upload', null, 'User/MyCompany', 'My Logo Upload'),
                "DELETE": getRouteObject('my-company/my-logo/delete', null, 'User/MyCompany', 'My Logo Delete'),
            },
        },
        "CUSTOMERS": {
            ...getRouteForModule('customers', 'Customer', 'Customers', 'Customer', 'icon-users4'),
            "CREDIT_CARDS": getRouteForModule('customers/credit-cards', 'Customer', 'Credit Cards', 'Credit Card'),
        },
        "PLANS": getRouteForModule('plans', 'Plan', 'Plans', 'Plan','icon-file-text2'),
        "SUBSCRIPTIONS": {
            ...getRouteForModule('subscriptions', 'Subscription', 'Subscriptions', 'Subscription','icon-stack'),
        },
        "PAYMENTS": {
            ...getRouteForModule('payments', 'Payment', 'Payments', 'Payment','icon-coin-dollar'),
            "REFUND": getRouteForModule('refunds', 'Refund', 'Refunds', 'Refund','icon-coin-dollar')
        },
        "USERS": getRouteForModule('users', 'User', 'Users', 'User','icon-users4'),
        "SETTINGS": {
            "XERO": getRouteObject('xero/accounts', null, 'Settings/Xero', 'Xero Accounting'),
            "MAILCHIMP": getRouteObject('mailchimp/crm', null, 'Settings/MailChimp', 'MailChimp CRM')
        },
        "INTEGRATIONS": {
            "XERO": getRouteObject('xero/integrate', null, 'Integrations/Xero', 'Reconnect Xero'),
            "MAILCHIMP": getRouteObject('mailchimp/integrate', null, 'Integrations/MailChimp', 'Reconnect MailChimp'),
            "GOOGLE": getRouteObject('google-oauth/integrate', null, 'Integrations/Google', 'Connect your Google Account', 'Connect your Google Account', 'icon-google')
        },

        "STUDENTS": {
            ...getRouteForModule('students', 'Student', 'Students', 'Student', 'icon-users2'),
            "SIGNUP": getRouteObject('students/signup', null, 'Student/Signup', 'Signup'),
            "LOGIN": getRouteForModule('students/login', 'Student/Login', 'Student Login'),
            "SIGNUP_NEW_ACCOUNT": getRouteForModule('students/signup-new-account', null, null),
            "FORGOTTEN_PASSWORD": getRouteForModule('students/forgotten-password', 'Student/ForgottenPassword', 'Student Forgotten Password'),
            "REQUEST_FORGOTTEN_PASSWORD": getRouteForModule('students/request-forgotten-password', 'Student/RequestForgottenPassword', 'Student Request Forgotten Password'),
            "RE_ENROLL": getRouteForModule('students/re-enroll', 'Student/ReEnroll', 'ReEnroll', 'ReEnroll'),
            "TASK": getRouteForModule('students/tasks', 'Student/Task', 'Assessment'),
            "PAYMENT": getRouteObject('students/payments', null, 'Student/Payment', 'Payments'),
            "SIGN_WAIVER": getRouteObject('students/waiver', null, 'Student/Waiver', 'Sign Waiver'),
            "PROFILE": {
                ...getRouteForModule('students/profile', 'Student/Profile', 'Student Profile'),
                'CHILDREN': getRouteForModule('students/children', 'Student/Children', 'Children', 'Children'),
            },
            "EVENT": {
                ...getRouteObject('students/events', null, 'Student/Event', 'Book an Event'),
                'INSURANCE': getRouteObject('students/events/insurances', null),
            },
            "MY_BOOKING": {
                ...getRouteForModule('students/my-booking', 'Student/MyBooking', 'My Booking', 'My Booking')
            },
            "MY_PARTY_BOOKING": {
                ...getRouteForModule('students/my-party-booking', 'Student/MyPartyBooking', 'My Party Booking', 'My Party Booking'),
                "UPDATE_GUEST": getRouteObject('students/my-party-booking/update-guest', null, 'Student/MyPartyBooking/UpdateGuest', 'Update guest')
            },
            "REGISTRATIONS": {
                ...getRouteForModule('students/registrations', 'Student/Registration', 'Class Registrations', 'Class Registration'),
                "DISABLE_AUTO_ENROLL": getRouteObject('students/registrations/disable-auto-enroll', null, 'Student/Registration', 'Disable Auto Re-Enroll'),
                "CHECK_AUTO_ENROLL": getRouteObject('students/registrations/check-auto-enroll', null, 'Student/Registration', 'Check Auto Enroll'),
                "INVOICES": getRouteForModule('students/registrations/invoices', 'Student/Registration/Invoice', 'Invoices', 'Invoices'),
            },
            "INVITE": getRouteForModule('students/invite', 'Student/Invite', 'Invite', 'Invite'),
            "CLASSES": {
                ...getRouteForModule('students/classes', 'Student/Classes', 'Classes', 'Class'),
                "PAYMENTS": getRouteForModule('students/classes/payments', 'Student/Classes/Payment', 'Payment Schedule', 'Payment Schedule'),
                "SCHEDULE": {
                    "CHECK": getRouteObject('students/classes/check-schedule', null, 'Student/Classes/Payment', 'Check Schedule'),
                    "LOAD": getRouteObject('students/classes/load-schedule', null, 'Student/Classes/Payment', 'Load Schedule'),
                },
                "ATTENDANCE": getRouteObject('students/classes/attendance', null, 'Attendance', 'Attendance'),
            },
            "UNIFORMS": getRouteForModule('students/uniforms', 'Student/Uniforms', 'Uniforms', 'Uniform'),
            "INSURANCES": getRouteForModule('students/insurances', 'Student/Insurances', 'Insurances', 'Insurance'),
            "PRIVATE_CLASSES": {
                ...getRouteForModule('students/private-classes', 'Student/PrivateClass', 'Private Classes', 'Private Class'),
                "SESSIONS": getRouteObject('students/private-classes/sessions', null, 'Student/PrivateClass', 'Private Class'),
            },
            "INVOICES": {
                ...getRouteForModule('students/invoices', 'Student/Invoices', 'Invoices', 'Invoice'),
                "STATS": getRouteObject('students/invoices/stats', null, 'Invoices', 'Invoice Stats'),
            },
            "ABSENCE_DAY": {
                ...getRouteForModule('students/absence-day', 'Student/AbsenceDay', 'Absence Days', 'Absence Days'),
                "CHECK_CLASS_DATES": getRouteObject('students/check-class-dates', null, 'Check Class Dates', 'Class Dates')
            },
            "FLAG": {
                ...getRouteForModule('students/flags', 'Student/Flag', 'Flag', 'Flag'),
            }
        },

        "CLASSES": {
            ...getRouteForModule('term-classes', 'TermClass', 'Classes', 'Class','icon-users4'),
            "SESSIONS": getRouteForModule('class-sessions', 'ClassSession', 'Sessions', 'Session'),
            "PAYMENTS": getRouteForModule('class-payments', 'ClassPayment', 'Payment Schedule', 'Payment Schedule'),
            "STUDENTS": {
                ...getRouteForModule('class-students', 'ClassStudent', 'Registered Students', 'Registered Students'),
                "COUNT": getRouteObject('term-classes/class-students/active-students-count', null, 'ClassStudent', 'Registered Students'),
                "RESEND_CONFIRMATION_EMAIL": getRouteObject('term-classes/class-students/resend-confirmation-email', null, 'ClassStudent', 'Registered Students')
            },
            "CSV": getRouteObject('term-classes/csv', null, 'CSV', 'Export to CSV'),
            "LIST": getRouteObject('term-classes/list', null, 'List', 'List Classes'),
            "PDF": getRouteObject('term-classes/pdf', null, 'PDF', 'Export to PDF')
        },
        "INVOICES": getRouteForModule('invoices', 'Invoice', 'Invoices', 'Invoice','icon-cash'),
        "COMMUNICATIONS": {
            ...getRouteForModule('communications', 'Communication', 'Mass Mail', 'Mass Mail','envelop3'),
            "CHECK": getRouteObject('communications/check', null, 'Communication/Check', 'Check Route'),
            "FILTER": getRouteObject('communications/filter', null, 'Communication/Filter', 'Filter Audience'),
            "FILTER_RESULT": getRouteObject('communications/filter/result', null, 'Communication/Filter/Result', 'Results for Filter Audience'),
        },
        "REPORTS": {
            "DAILY_ROLL": getRouteForModule('reports/daily-roll', 'Reports/DailyRoll', 'Daily Roll'),
            "PDF": getRouteObject('reports/daily-roll/pdf', null, 'PDF', 'Export to daily roll PDF'),
            "INSURANCE": getRouteForModule('reports/insurance', 'Reports/Insurance', 'Insurance'),
            "COUPON_CODE_USAGE": getRouteForModule('reports/coupon-code-usages', 'Reports/CouponCodeUsage', 'Coupon Code Usages', 'Coupon Code Usage'),
            "TRIAL_CONVERSIONS": getRouteForModule('reports/trial-conversions', 'Reports/TrialConversion', 'Trial Conversion', 'Trial Conversion'),
            "ENROLLMENT_COMPARISON": getRouteForModule('reports/enrollment-comparison', 'Reports/EnrollmentComparison', 'Enrollment Comparison', 'Enrollment Comparison'),
        },
        "REMINDER_EMAILS": {
            ...getRouteForModule('reminder-emails', 'ReminderEmail', 'Email Reminders', 'Email Reminder','envelop2'),
            "VARIABLES": getRouteObject('reminder-emails/variables', null, 'ReminderEmail/Variables', 'Variables'),
        },
        "SYSTEM_EMAILS": {
            ...getRouteForModule('system-emails', 'SystemEmail', 'System Emails', 'System Email','envelop2'),
            "VARIABLES": getRouteObject('system-emails/variables', null, 'SystemEmail/Variables', 'Variables'),
            "LIST_INSURANCE": getRouteObject('system-emails/list-insurance', null, 'SystemEmail/ListInsurance', 'ListInsurance'),
        },
        "COACHES": {
            ...getRouteForModule('coaches', 'Coach', 'Coaches', 'Coach','people'),
            "PRIVATE_SESSIONS": {
                ...getRouteForModule('coaches/private-sessions', 'Coach/PrivateSession', 'Private Sessions', 'Private Session'),
                "SESSIONS": getRouteObject('coaches/private-sessions/sessions', null, 'Coach/PrivateSession', 'Private Class Sessions'),
            },
            "LEAVES": {
                ...getRouteForModule('coaches/leaves', 'Coach/Leave', 'Leave Management', 'Leave Management'),
            },
            "UNIFORMS": getRouteForModule('coaches/uniforms', 'Coach/Uniforms', 'Uniforms', 'Uniform'),
        },

        "TERMS": getRouteForModule('terms', 'Term', 'Terms', 'Term','alarm-check'),
        "CLASS_TYPES": getRouteForModule('class-types', 'ClassType', 'Class Types', 'Class Type','icon-list3'),
        "TASK_LIST_CLASS": getRouteForModule('task-list-class', 'TaskListClass', 'Task List Class', 'Task List Class'),
        "TASK_LIST": getRouteForModule('task-lists', 'TaskList', 'Task List', 'Task List','icon-task'),
        "TASK": {
            ...getRouteForModule('tasks', 'Task', 'Task', 'Task','icon-task'),
            "TASK_STUDENT": getRouteObject('task/students', null, 'TaskStudent', 'Task Student'),
            "TASK_STUDENT_COMMENT": getRouteObject('task/student-comment'),
        },
        "UNIFORM_TYPES": getRouteForModule('uniform-types', 'UniformType', 'Merchandise', 'Merchandise','icon-rating3'),
        "UNIFORM_SIZES": getRouteForModule('uniform-sizes', 'UniformSize', 'Sizes', 'Size','icon-rating3'),
        "VENUES": getRouteForModule('venues', 'Venue', 'Venues', 'Venue','icon-office'),
        "ROOMS": getRouteForModule('rooms', 'Room', 'Rooms', 'Room','icon-office'),
        "COUPONS": getRouteForModule('coupons', 'Coupon', 'Coupons', 'Coupon','icon-piggy-bank'),
        "INSURANCES": {
            ...getRouteForModule('insurances', 'Insurance', 'Insurances', 'Insurance','icon-shield2'),
            "LIST": getRouteObject('insurances/list', null, 'List', 'List Insurances'),
        },
        "RESTRICTED_DATES": getRouteForModule('restricted-dates', 'RestrictedDates', 'Restricted Dates', 'Restricted Date','icon-calendar52'),
        "WAIVER": {
            ...getRouteForModule('waivers', 'Waiver', 'Waivers', 'Waiver','icon-file-check'),
            "LIST": getRouteObject('waivers/list', null, 'List', 'List Waivers'),
        },
        "PAYMENT_TERMS": {
            ...getRouteForModule('payment-terms', 'PaymentTerms', 'Payment Terms', 'Payment Terms','icon-file-text2'),
            "LIST": getRouteObject('payment-terms/list', null, 'List', 'List Payment Terms'),
        },
        "SURCHARGES": getRouteForModule('surcharges', 'Surcharge', 'Surcharges', 'Surcharge','coin-dollar'),
        "REGISTRATION_FORM": {
            ...getRouteForModule('registration-forms', 'RegistrationForm', 'Registration Forms', 'Registration Form','icon-browser'),
            "SETTINGS": getRouteForModule('registration-forms/settings', 'RegistrationForm/Settings', 'Settings', 'Settings'),
            "ANOMALIES": getRouteForModule('registration-forms/anomalies', 'RegistrationForm/Anomalies', 'Anomalies', 'Anomalies'),
            "EMBED": getRouteForModule('registration-forms/embed', 'RegistrationForm/Settings', 'Embed', 'Embed'),
        },
        "COMPANY_SIGNUP": {
            ...getRouteObject('company/signup', null, 'Signup', 'Signup'),
            "FINAL": getRouteObject('company/signup/final', null, 'Signup/Final', 'Signup Final'),
            "CANCEL": getRouteObject('company/signup/cancel', null, 'Signup/Cancel', 'Signup Cancel'),
            "CART": getRouteObject('company/signup/cart', null, 'Signup Cart', 'Signup Cart'),
            "SETTINGS": getRouteObject('company/signup/settings', null, 'Signup Settings', 'Signup Settings')
        },
        "PAYMENT_INVOICE": {
            ...getRouteObject('student/payment', null, 'PaymentInvoice', 'Payment Invoice'),
            "INVOICE": getRouteObject('student/payment/invoice', null, 'Payment Invoice', 'Payment Invoice'),
        },

        "MODULES": getRouteForModule('modules', 'Module', 'Modules', 'Module','icon-grid6'),
        "EMAILS": getRouteForModule('settings/emails', 'Emails', 'Emails', 'Emails','icon-envelope'),

        "CRM": getRouteForModule('settings/crm', 'Crm', 'CRM', 'CRM','icon-users2'),
        "PROMO": getRouteForModule('promotional-codes', 'PromoCode', 'Promotional Codes', 'Promotional Code','icon-piggy-bank'),
        "FLAG": getRouteForModule('flags', 'Flag', 'Flag', 'Flag','icon-flag3'),
        "EVENT": {
            ...getRouteForModule('events', 'Event', 'Events', 'Event','icon-grid5'),
            "SEND_TEST_REMINDER": getRouteObject('events/send-test-reminder', null, 'Send Test Reminder', 'Send Test Reminder'),
            "SESSION": getRouteForModule('events/sessions', 'Event/Session', 'Session', 'Session'),
            "TICKET": getRouteForModule('events/tickets', 'Event/Ticket', 'Ticket', 'Ticket'),
            "PRICE_TIER": getRouteForModule('events/price-tiers', 'Event/PriceTier', 'PriceTier', 'PriceTier'),
            "BOOKING": getRouteForModule('events/bookings/students', null, null, null),
        },
        "BOOKING": {
            ...getRouteForModule('events/bookings', 'Booking', 'Bookings', 'Booking','icon-file-text2'),
            "WAIVER": {
                ...getRouteForModule('events/bookings/waivers', 'Booking/Waiver', 'Event Waiver', 'Event Waiver','icon-text2'),
                "LINK": getRouteObject('events/bookings/waivers/link', null, 'Booking/Waiver/Link', 'Event Waiver Link', 'Event Waiver Link')
            },
            "SWAP_SESSION": getRouteForModule('events/bookings/swap-session', 'Booking/SwapSession', 'Swap Session', 'Swap Session'),
            "CHANGE_PARENT": getRouteForModule('events/bookings/change-parent', 'Booking/ChangeParent', 'Change Parent', 'Change Parent'),
            "PAYMENT": getRouteForModule('events/bookings/payments', 'Booking/Payment', 'Payment', 'Payment'),
            "STUDENTS": getRouteForModule('events/bookings/students', null, null, null),
            "REPORT": {
                "LIST": getRouteObject('events/bookings/reports', null, null, 'Reports', 'Reports', 'icon-books'),
                "DAILY_ROLL": getRouteObject('events/bookings/reports/daily-roll', null, 'Booking/Report/DailyRoll', 'Daily Roll', 'Daily Roll'),
                "PAYMENT": getRouteObject('events/bookings/reports/attendee-payment', null, 'Booking/Report/Payment', 'Payment', 'Payment'),
                "WAITING_LIST": getRouteObject('events/bookings/reports/waiting-list', null, 'Booking/Report/WaitingList', 'Waiting List', 'Waiting List'),
                "WAIVER": getRouteObject('events/bookings/reports/waivers', null, 'Booking/Report/Waiver', 'Waiver List', 'Waiver List')
            }
        },
        "CONTACT": getRouteForModule('contacts', 'Contact', 'Contact', 'Contact','icon-users4'),
        "SLOT": getRouteForModule('slots', 'Slot', 'Party Slots', 'Party Slots','icon-alarm'),
        "PRICING_AND_SETTING": getRouteObject('birthday-pricing', null, 'PricingAndSetting', 'Pricing and Settings', 'Pricing and Settings'),
        "INVITATION_SETTING": {
            ...getRouteObject('party-invitation-settings', null, 'PartyInvitationSetting', 'Invitation Setting', 'Invitation Setting'),
            "INVITATION_IMAGE": {
                ...getRouteObject('party-invitation-settings/invitation-image', null, 'PartyInvitationSetting', 'Invitation Image'),
                "UPLOAD": getRouteObject('party-invitation-settings/invitation-image/upload', null, 'PartyInvitationSetting', 'Invitation Image Upload'),
                "DELETE": getRouteObject('party-invitation-settings/invitation-image/delete', null, 'PartyInvitationSetting', 'Invitation Image Delete'),
                "PREVIEW": getRouteObject('party-invitation-settings/preview', null, 'PartyInvitationSetting', 'Invitation Image Preview'),
            },
        },
        "BIRTHDAY_PARTY": {
            "BOOKING": getRouteForModule('birthday-party/bookings', 'BirthdayParty/Booking', 'Booking', 'Booking','icon-file-text2'),
            "WAIVER": {
                ...getRouteForModule('birthday-party/waivers', 'BirthdayParty/Waiver', 'Party Waiver', 'Party Waiver','icon-file-text2'),
                "LINK": getRouteObject('birthday-party/waivers/link', null, 'BirthdayParty/Waiver/Link', 'Party Waiver Link', 'Party Waiver Link')
            },
            "PAYMENT": getRouteObject('birthday-party/bookings/payments', null, 'Booking/Report/Payment', 'Payment', 'Payment'),
            "REPORT": {
                "UPCOMING_PARTY": getRouteObject('birthday-party/bookings/reports/upcoming-parties', null, 'BirthdayParty/Report/UpcomingParty', 'Upcoming Party', 'Upcoming Party'),
                "PAYMENT": getRouteObject('birthday-party/bookings/reports/payments', null, 'BirthdayParty/Report/Payment', 'Payment', 'Payment'),
                "WAIVER": getRouteObject('birthday-party/bookings/reports/waivers', null, 'BirthdayParty/Report/Waiver', 'Waiver', 'Waiver'),
            }
        },
    }
};
