import {
    React,
    useEffect,
    $,
    useLocation,
    queryString,
    useActionUrl,
    Form,
    Table,
    MetaTable,
    PageHeader,
    CompanyRoutes,
    useFormInit,
    getGetParam,
    showMessage,
    showUserMessage,
    AdminRoutes,
    ModalForm,
    urlConfigs
} from 'library/base/baseContainerImports'
import Loading from './Components/Loading'
import { useState } from '../../library/base/baseContainerImports'


const UpdatePartyGuest = () => {

    const location = useLocation();
    const {id, authhash, fid} = queryString.parse(location.search);

    const [loading, setLoading] = useState(false);
    const [companyLogo, setCompanyLogo] = useState('');

    const {getFormActions} = useActionUrl();

    const {formAction, formDefinitionAction} = getFormActions('/birthday-party/update-guest', {id, count: 10});
    const {fetchFormData} = useFormInit({formAction});

    const companyBranding = () => {
        setLoading(true)
        const formAction    = urlConfigs.BASE_URL + '/rest/acl?authhash=' + authhash;
        $.get(formAction, function (data){
            if (data?.meta?.logo) {
                setCompanyLogo(data.meta.logo);
            }
            setLoading(false)
        });
    };

    useEffect(() => {
        companyBranding();
    }, []);

    return (
        <div className="waiver" style={{ width: '100vw' }}>
            <div className="container">
                {loading ? (<Loading/>) : (
                    <>
                        <div
                            className="display-block text-slate-300 border-bottom border-color-light-grey pb-10 mb-10 text-center">
                            {companyLogo && <img alt="Logo" src={companyLogo}/>}
                        </div>
                        <div>
                            <div className="activate_message"></div>
                            <Form
                                id="form-data"
                                action={formAction}
                                formClass="form_data generic_form"
                                encType="application/x-www-form-urlencoded"
                                jsonLoadAction={formDefinitionAction}
                                jsonLoadActionHandler={fetchFormData}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default UpdatePartyGuest;