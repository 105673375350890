import React, { useEffect, useRef } from 'react'
import BookingInfo from './Components/BookingInfo'
import { useSelector } from 'react-redux'
import {
    $, CompanyRoutes,
    queryString, showMessage,
    urlConfigs,
    useActionUrl,
    useLocation,
    useState
} from '../../library/base/baseContainerImports'
import { useHistory } from 'react-router-dom'
import CouponForm from './Components/CouponForm'
import { formatter } from './Components/BookingForm'
import BirthDayPartyTermsAndConditionsForm from './Components/BirthDayPartyTermsAndConditionsForm'
import { loadStripe } from '@stripe/stripe-js'
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js'
import { formatDate } from './BirthdayPartyBooking'

const BirthdayPartyCheckout = () => {
    const history = useHistory();
    const location = useLocation();
    const {getFullUrl} = useActionUrl();
    const ref = useRef();

    const checkoutData = JSON.parse(localStorage.getItem('checkoutBirthdayData'));
    const userToken = useSelector(state => state.auth.token);
    const [pricing, setPricing] = useState(checkoutData.pricing);
    const {authhash} = queryString.parse(location.search);
    const [loadingCoupon, setLoadingCoupon] = useState(false);
    const [loading, setLoading] = useState(false);
    const [acceptTermAndCondition, setAcceptTermAndCondition] = useState(false);
    const [stripePublicKey, setStripePublicKey] = useState('');
    const [clientSecret, setClientSecret] = useState('');
    const [companyLogo, setCompanyLogo] = useState('');
    const [paymentTerms, setPaymentTerms] = useState('');
    const [userMeta, setUserMeta] = useState({});
    const [children, setChildren] = useState([]);
    const [childSelected, setChildSelected] = useState({ id: null, first_name: null, last_name:null, dob: null });

    const addCoupon = (code) => {
        setLoadingCoupon(true);
        const formAction = getFullUrl(
            "/api/v1/calculate-charges-summary",
            {
                authhash,
                date: pricing.date,
                quantity: pricing.quantity,
                type_of_registration: 'party',
                coupon_code: code
            }
        )
        $.get(formAction, function (data) {
            setPricing(data);
            $(".form-coupon").find('.nNote').remove();
            if (data.errors.invalid_coupon_code_message)
                showMessage ( $(".form-coupon") , data.errors.invalid_coupon_code_message);
            setLoadingCoupon(false);
        }).fail(() => {
            setLoadingCoupon(false);
        });
    }
    const companyBranding = () => {
        const formAction    = urlConfigs.BASE_URL + '/rest/acl?authhash=' + authhash;
        $.get(formAction, function (data){
            if (data?.meta?.logo) {
                setCompanyLogo(data.meta.logo);
            }
        });
    };

    const getChildren = () => {
        const childrenAction    = getFullUrl(CompanyRoutes.ADMIN.STUDENTS.PROFILE.CHILDREN.path);
        $.get(childrenAction, function (data) {
            if(Array.isArray(data)) setChildren(data);
            return false;
        });
    }

    const getPaymentTerm = () => {
        const paymentTermAction    = getFullUrl('/api/v1/birthday-party/payment-terms', {authhash});
        $.get(paymentTermAction, function (data) {
            if (data.payment_terms) setPaymentTerms(data.payment_terms)
        });
    }

    const fetchUserMeta = () => {
        const formAction    = urlConfigs.BASE_URL + '/rest/acl?authhash=' + authhash;
        $.get(formAction, function (data){
            if (data) {
                setUserMeta({
                    full_name: data.name,
                    ...data.meta
                });
            }
        });
    }

    const handleCheckout = () => {
        if (!acceptTermAndCondition) {
            showMessage ( $(".form-checkout") , "You need to accept our Terms and Conditions before placing this booking" );
            ref.current.scrollIntoView({ behavior: 'smooth' });
            return false;
        }

        if (!childSelected.id && (!childSelected.first_name || !childSelected.first_name || !childSelected.dob)) {
            showMessage ( $(".form-checkout") , "Child's information is required!" );
            ref.current.scrollIntoView({ behavior: 'smooth' });
            return false;
        }

        setLoading(true);
        const formData = {
            amount: pricing.pricing_details.total.value,
            surcharge: pricing.pricing_details.surcharge.value,
            deposit: pricing.pricing_details.deposit.value,
            discount_amount: pricing?.pricing_details?.coupon?.value ?? 0,
            discount_reason: "Coupon Code",
            coupon_code: pricing.additional_information.coupon_code ?? null,
            student: childSelected,
            ...checkoutData
        }
        const checkoutUrl = getFullUrl('/api/v1/birthday-party/booking/checkout', {authhash});

        let stripeCheckoutSessionId = null;

        $.post(checkoutUrl, formData, function (data) {
            if (data.public_key && data.client_secret && data.sessionId) {
                setStripePublicKey(loadStripe(data.public_key));
                setClientSecret(data.client_secret);
                stripeCheckoutSessionId = data.sessionId;
                $('#stripeCheckout').modal('show');

                $('#stripeCheckout').on('hidden.bs.modal', function (e) {
                    let cancelAction = getFullUrl('/integrations/stripe/cancel-session', {
                        session: stripeCheckoutSessionId
                    });
                    $.post(cancelAction, {userAction: 'expire'}, function (data) {
                        return false;
                    });
                    return true;
                });

            } else {
                ref.current.scrollIntoView({ behavior: 'smooth' });
                showMessage ( $(".form-checkout") , data.message);
            }
            setLoading(false);
        }).fail(function (jqXHR, status, a) {
            let errorMessage = "Something went wrong!";
            if (jqXHR.responseJSON && jqXHR.responseJSON.message) {
                errorMessage = jqXHR.responseJSON.message;
            }
            ref.current.scrollIntoView({ behavior: 'smooth' });
            showMessage($(".form-checkout"), errorMessage);
            setLoading(false);
        })
    }

    useEffect(() => {
        if (!userToken) {
            const checkoutPath = `/booking/checkout?authhash=${authhash}`;
            const checkoutUrl = urlConfigs.LOCAL_URL.replace('/admin/', '') + checkoutPath
            history.push(`admin/students/login?authhash=${authhash}&redirect=${checkoutUrl}`);
        }

        companyBranding();
    }, []);

    useEffect(() => {
        if (companyLogo) {
            fetchUserMeta();
            getChildren();
            getPaymentTerm();
        }
    },[companyLogo]);

    const handleChildChange = (value, field) => {
        setChildSelected(prevState => ({
            ...prevState,
            [field]: value
        }));
    };

    return (
        <div className="booking-event">
            <div className="container">
                <div className=" border-bottom border-color-light-grey pb-10 mb-10">
                    <div className="display-block text-slate-300 text-center">
                        {companyLogo && <img alt="Logo" src={companyLogo}/>}
                    </div>
                    {userToken && <a href={CompanyRoutes.ADMIN.STUDENTS.PROFILE.path}><i
                        className="icon-arrow-left7"></i> Go
                        to my account</a>}
                </div>

                <div className="row mb-20 form-checkout" ref={ref}>
                    <div className="col-md-12">
                        <div>
                            <h6>Child 1.</h6>
                        </div>
                        <div>
                            {children.length > 0 ? (
                                <select
                                    className="form-control"
                                    onChange={(e) => handleChildChange(e.target.value, 'id')}
                                >
                                    <option>---------Select child---------</option>
                                    {children.map(child => {
                                        return (
                                            <option value={child.id}>{child.first_name} {child.last_name}</option>
                                        )
                                    })}
                                </select>
                            ): (
                                <div>
                                    <div className="col-md-6 mb-10">
                                        <label>Child's First Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter new child's first name"
                                            onChange={(e) => handleChildChange(e.target.value, 'first_name')}
                                        />
                                    </div>
                                    <div className="col-md-6 mb-10">
                                        <label>Child's Last Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter new child's last name"
                                            onChange={(e) => handleChildChange(e.target.value, 'last_name')}
                                        />
                                    </div>
                                    <div className="col-md-6 mb-10">
                                        <label>Child's Date of Birth</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            placeholder="Enter new child's date of birth"
                                            onChange={(e) => handleChildChange(e.target.value, 'dob')}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <CouponForm addCoupon={addCoupon} loading={loadingCoupon}
                                    coupon={pricing.additional_information.coupon_code || ''}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <BirthDayPartyTermsAndConditionsForm setAcceptTermAndCondition={setAcceptTermAndCondition}
                                                             paymentTerms={paymentTerms}
                                                             acceptTermAndCondition={acceptTermAndCondition}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <BookingInfo checkoutData={checkoutData} userData={userMeta}/>
                    </div>
                </div>
                {
                    Object.keys(pricing.pricing_details).map((key) => {
                        const item = pricing.pricing_details[key]
                        return (
                            <div className="row highlighted-row">
                                <div className="col-sm-6 hidden-xs">
                                    <h5>{item.label}</h5>
                                    {item.description && (
                                        <small>{item.description}</small>
                                    )}
                                </div>
                                <div className="col-sm-6 " align="right">
                                    <h5>{item.formatted}</h5>
                                </div>
                            </div>
                        )
                    })
                }
                <div className="row pull-right">
                    <div className="col-md-12 mt-10 no-padding-left no-padding-right">
                        <button
                            type="button"
                            onClick={handleCheckout}
                            className="btn btn-primary"
                            disabled={loading}
                        >Confirm Payment
                        </button>
                    </div>
                </div>

            </div>
            {clientSecret && <div class="modal fade" id="stripeCheckout" tabindex="-1" role="dialog"
                                  aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Stripe Checkout</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div id="checkout">
                                <EmbeddedCheckoutProvider
                                    stripe={stripePublicKey}
                                    options={{clientSecret}}
                                >
                                    <EmbeddedCheckout />
                                </EmbeddedCheckoutProvider>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    );
}
export default BirthdayPartyCheckout;